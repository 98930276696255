import React from "react";
import "./styles.scss"
import profil from "../../icons/profil2-min.png";

function about() {

  return(
    <div className="about">
        <div className="about-element">
          <div className="about-component">
            <h2 className="about-title">Qui suis-je ?</h2>
            <p className="about-text">Oléronaise depuis toujours, je connais très bien le territoire insulaire.</p>
            <p className="about-text">Pendant plus de dix ans, je me suis forgée une solide expérience dans le domaine de l’urbanisme au sein
            d’une collectivité locale. Je suis familière avec la lecture des documents réglementaires, avec la
            composition d’un dossier d’urbanisme (permis de construire, déclaration préalable, certificat d’urbanisme
            etc...) et avec l’exécution des règles applicables.</p>
            <p className="about-text">Je suis une personne créative qui aime accompagner les porteurs de projet immobilier. Je suis organisée et
            capable de gérer plusieurs dossiers en simultané tout en respectant les délais. En tant que
            professionnelle, je suis attentive aux détails, capable de travailler en équipe et de communiquer
            efficacement avec les clients, les partenaires et les entrepreneurs.</p>
            <p className="about-text">Ainsi, je travaille en collaboration avec des architectes locaux, une architecte d’intérieur, des maçons et
            professionnels du bâtiment, des bureaux d’étude et des acteurs locaux de l’immobilier.</p>
          </div>
        <img className="about-img" src={profil} alt="profil Emilie" />
        </div>
    </div>
  );
}

export default about;
