import React from "react";
import "./styles.scss";
import PPRN from "../../icons/PPRN-min.png";
import siteclasse from "../../icons/siteclassé-min.jpg";
import siteclasse480px from "../../icons/siteclassé-min480px.jpg";
import loilittoral from "../../icons/loilittoral-min.jpg";
import loilittoral480px from "../../icons/loilittoral-min480px.jpg";

function Reglementations() {

  return (
    <div className="reglementations">
      <div className="reglementations-element">
        <div className="reglementations-component">
        <h2 className="reglementations-title">- Réglementations -</h2>
          <ul className="reglementations-lists">
              <li className="reglementations-list">
                <h3 className="reglementations-list-title">Plan Local d'Urbanisme</h3>
                <p className="reglementations-list-text">Le Plan local d’urbanisme ou PLU détermine un projet global d’aménagement et fixe des règles
                  applicables sur un territoire. Il remplace le POS (plan d’occupation des sols) depuis 2000.</p>
                <p className="reglementations-list-text">Il est régi par les dispositions du code de l’urbanisme et doit être compatible avec les autres
                  réglementations qui le précèdent: SCOT, loi Littoral, Plan Local de l’Habitat, Plan de Déplacements
                  Urbains, Trame Verte et Bleue, Orientations d’Aménagement et de Programmations etc.</p>
                <p className="reglementations-list-text">Il est constitué d’un rapport de présentation, d’un Projet d’Aménagement et de Développement Durable,
                d’un plan de zonage, d’un règlement, et d’annexes.</p>
                <p className="reglementations-list-text">Le PLU comprend également un volet «servitudes» comme les Plans de Préventions des Risques 
                Naturels, le droit de préemption, le périmètre des monuments historiques etc.</p>
                <p className="reglementations-list-text">Le plan de zonage est divisé en 4 types de zones :
                <ul>
                  <li>U pour les zones urbanisées</li>
                  <li>AU pour les zones à urbaniser</li>
                  <li>N pour les zones naturelles</li>
                  <li>A pour les zones agricoles</li>
                </ul>
                </p>
                <p className="reglementations-list-text">Le règlement du PLU est un document réglementaire d'urbanisme qui définit les modalités de gestion du 
                sol. Il fixe les différentes règles applicables à chaque zone: constructibilité, implantation, hauteur, emprise 
                au sol, aspect, stationnement, accès etc.</p>
                <p className="reglementations-list-text">A ce jour, il n’y a pas de PLU intercommunal sur l’île d’Oléron. Ainsi, chaque commune a son PLU.
                A retrouver sur ce lien : 
                <br />
                  <a className="reglementations-link" href="https://www.cdc-oleron.com/vivre-et-habiter/urbanisme/reglementations/"> Réglementation et zonage - Communauté de communes de l'Ile d'Oléron (cdc-oleron.com)
                  </a>
                </p>
              </li>
              <li className="reglementations-list">
                <h3 className="reglementations-list-title">Plan de Prévention des Risques Naturels</h3>
                  <p className="reglementations-list-text">Le Plan de Prévention des Risques Naturels ou PPRN définit une zone géographique exposée à des
                  risques naturels afin d’y prescrire des mesures permettant de réduire les risques encourus pour protéger
                  les personnes, les biens et l’environnement.</p>
                  <p className="reglementations-list-text">Sur l’île d’Oléron, des risques Feu de forêt, Submersion et Érosion ont été répertoriés dans un PPRN
                  approuvé en Août 2018. Ce document a été élaboré par les services de l’État.</p>
                  <p className="reglementations-list-text">Le PPRN est un document consultable sur le site du département de la Charente Maritime via ce lien :
                  <br /> 
                    <a className="reglementations-link" href="https://www.charente-maritime.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/Risques-naturels-et-technologiques/Les-plans-de-prevention-des-risques-naturels-PPRN-approuves/Ile-d-Oleron">Île d'Oléron - Les plans de prévention des risques naturels (PPRN) approuvés - Risques naturels et
                    technologiques - Environnement, risques naturels et technologiques - Actions de l'État - Les services de
                    l'État en Charente-Maritime
                    </a> 
                  </p>
                  <img className="reglementations-list-img" src={PPRN} alt="plan de prévention des risques naturels" />
              </li>
              <li className="reglementations-list">
                <h3 className="reglementations-list-title">Charte Architecturale du Pays Marennes Oléron</h3>
                <p className="reglementations-list-text">La charte architecturale de l’île d’Oléron est un document qui vous donne des conseils pour respecter le
                caractère insulaire du territoire. Elle aborde les aspects du paysage, de l’urbanisme et de l’architecture.</p>
                <p className="reglementations-list-text">Son volet «histoire» est particulièrement intéressant car il permet de comprendre l’origine des règles
                imposées sur Oléron. 
                <br />
                  <a className="reglementations-link" href="https://caue17.com/wp-content/uploads/2014/04/charte-MO_100.pdf">Charte du pays Marennes Oléron.
                  </a>
                </p>
              </li>
              <li className="reglementations-list">
                <h3 className="reglementations-list-title">Site Classé</h3>
                <p className="reglementations-list-text">85% du territoire de l’île d’Oléron est situé en site classé depuis le décret du 1er avril 2011. Ce classement reconnaît l’île d’Oléron comme ayant un paysage de qualité à préserver.</p>
                <p className="reglementations-list-text">Le site classé permet la construction mais l’encadre de manière relativement stricte. Les dossiers en site
                classé sont plus longs à obtenir (2 mois pour une déclaration préalable, 8 mois pour un permis de
                construire). Un dossier en site classé fait souvent l’objet d’un avant projet ,éventuellement suivi d’une
                visite sur site avec l’architecte des bâtiments de France et l’inspecteur des sites.</p>
                <p className="reglementations-list-text">La carte du site classé : 
                  <a className="reglementations-link" href="https://www.cdc-oleron.com/wp-content/uploads/2021/07/planscadastraux8communes.pdf">Télécopie pleine page (cdc-oleron.com)
                  </a>
                </p>
                <p className="reglementations-list-text">Pour en savoir plus sur le site classé : <a className="reglementations-link" href="https://www.cdc-oleron.com/wp-content/uploads/2021/07/Depliant-Oleron.pdf">Depliant-Oleron.pdf (cdc-oleron.com)</a></p>
                <img className="reglementations-list-img" 
                src={siteclasse} 
                srcSet={`${siteclasse480px} 480w`}
                sizes="(max-width: 480px)"
                alt="siteclasse" />
              </li>
              <li className="reglementations-list">
                <h3 className="reglementations-list-title">Loi Littoral</h3>
                <p className="reglementations-list-text">La loi Littoral est une loi de 1986 qui vise à aménager, protéger et mettre en valeur le littoral. Sur notre
                territoire, elle s’applique dans toute l’île d’Oléron et même au-delà. Elle impose des règles d’urbanisme
                pour préserver les équilibres biologiques et écologiques, la lutte contre l'érosion, les sites et paysages, le
                patrimoine, les activités économiques liées à l'eau, l'agriculture, l'artisanat, le tourisme et l'accès du
                public.</p>
                <p className="reglementations-list-text">Si cette loi existe depuis plus de 30 ans, elle n’est appliquée strictement que depuis quelques années sur
                notre territoire. Ainsi, tous les PLU ne sont pas compatibles avec cette loi. Seuls ceux qui ont été révisés
                ces dernières années l’ont intégrée (comme Dolus d’Oléron ou La Brée les Bains par exemple). Sur les
                autres communes, il arrive que certains terrains pourtant classés en zone urbaine, soient inconstructibles
                du fait de l’application de la loi Littoral.</p>
                <p className="reglementations-list-text">Ces données sont à vérifier au cas par cas auprès des services urbanismes.</p>
                <img className="reglementations-list-img" 
                src={loilittoral} 
                srcSet={`${loilittoral480px} 480w`}
                sizes="(max-width: 480px)"
                alt="loilittoral" />
              </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Reglementations;
