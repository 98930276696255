import React from "react";
import "./styles.scss";
import permisconstruire from "../../icons/permisconstruire-min.jpg";
import permisconstruire480 from "../../icons/permisconstruire-min480px.jpg";

function talent() {
  return (
    <div className="talent">
      <div className="talent-element">
        <div className="talent-background"> 
         <div className="talent-fond">
          <h2 className="talent-title">- Savoir Faire -</h2>
          <p className="talent-text">Je propose mes services pour vous accompagner à élaborer vos projets immobiliers, dessiner vos
          plans, proposer des vues 3D et accomplir les démarches administratives liées au dépôt des autorisations
          d’urbanisme.</p>
          <p className="talent-text">
          Vos dossiers étant tous très différents les uns des autres, je vous propose de prendre contact avec
          moi pour tout projet afin d’établir ensemble un devis. Un rendez-vous sur site est souvent nécessaire pour
          évaluer la situation.</p>
         
          </div>
        </div>
          <div className="talent-component">
            <p className="talent-text-dossier">
            Voici les différents dossiers que je peux constituer pour vous :</p>
            <ul className="talent-lists">
              <li className="talent-list">
                <div className="talent-list-group">
                  <h3 className="talent-subtitle">L’avant-projet</h3>
                    <p className="talent-list-text">L’avant-projet n’est pas obligatoire mais il peut s’avérer utile avant le dépôt d’une autorisation
                    d’urbanisme, afin de s’assurer de la faisabilité d’un projet auprès de la mairie lors de la commission
                    d’urbanisme mensuelle.</p>
                    <p className="talent-list-text"> L’avant projet permet également d’être présenté et pré-validé par l’architecte conseil du CAUE (Conseil
                    d’architecture, d’urbanisme et de l’environnement) qui va s’attacher à l’aspect de la construction afin
                    qu’il soit cohérent avec la charte architecturale du Pays Marennes Oléron.</p>
                    <p className="talent-list-text">Un projet pré-validé par la mairie en commission d’urbanisme et par l’architecte conseil du CAUE a
                    toutes les chances de trouver une issue favorable et dans les meilleurs délais.</p>
                </div>
              </li>
              <li className="talent-list">
              <div className="talent-list-group">
                <h3 className="talent-subtitle">Le certificat d’urbanisme opérationnel</h3>
                <p className="talent-list-text">Tout comme l’avant projet, le CU opérationnel n’est pas obligatoire. Il est cependant idéal lorsque vous
                avez un projet de construction sur un terrain, vous appartenant ou non, et que vous vous posez la question
                de la faisabilité de votre projet. Il ne permet pas la construction mais permet de poser une question
                précise: Puis-je diviser ou construire ou agrandir etc.?</p>
                <p className="talent-list-text">
                L’administration saisie doit apporter une réponse dans un délai de deux mois. La réponse est créatrice de
                droit.</p>
                <p className="talent-list-text">Dans le cadre de ce dépôt, les concessionnaires (eau/assainissement, électricité etc.) sont consultés.
                Le certificat d’urbanisme est aussi très utile dans le cadre d’un futur achat, d’une donation, succession ou
                pour déterminer le prix de vente d’un terrain.</p>
                <p className="talent-list-text">
                  <a className="talent-link" href="https://www.service-public.fr/particuliers/vosdroits/R1970">
                    Demande de certificat d'urbanisme (Formulaire 13410*08) | Service-public.fr
                  </a>
                  </p>
              </div>
              </li>
              <li className="talent-list">
                <div className="talent-list-group"> 
                <h3 className="talent-subtitle">Le permis de construire</h3>
                  <p className="talent-list-text">Le permis de construire est une autorisation d'urbanisme que vous devez obtenir avant de construire un
                  bâtiment ou de faire certains travaux sur une construction existante.</p>
                  <p className="talent-list-text">Il est constitué d’un plan de situation, d’un plan de masse, de plans des façades, d’un plan de coupe, d’une
                  notice descriptive, d’une insertion paysagère et de photos prises sur site. Dans certains cas, il doit
                  comprendre une attestation de prise en compte de la réglementation thermique générée par un bureau
                  d’étude spécialisé.</p>
                  <p className="talent-list-text"> Le délai d’instruction d’un permis de construire par la mairie est de deux mois pour une maison
                  individuelle située hors zone de protection. Les permis de construire, hors maison individuelle et hors
                  zone de protection, sont délivrés sous trois mois.</p>
                  <p className="talent-list-text">Une fois l’autorisation obtenue, un panneau de chantier doit être affiché sur le terrain pendant toute la
                  durée des travaux et les travaux doivent commencer dans un délai de trois ans maximum.</p>
                  <p className="talent-list-text">
                    <a className="talent-link" href="https://www.service-public.fr/particuliers/vosdroits/R11637">
                      Demande de permis de construire pour une maison individuelle et/ou ses annexes (PCMI) (Formulaire 13406*11) | Service-public.fr
                    </a>
                  </p>
              </div>
              </li>
              <li className="talent-list">
              <div className="talent-list-group">
                <h3 className="talent-subtitle">La déclaration préalable</h3>
                <p className="talent-list-text">La déclaration préalable est une autorisation d'urbanisme obligatoire à la réalisation de certains travaux
                pour lesquels il n'y a pas à demander un permis de construire.</p>
                <p className="talent-list-text">Elle est déposée pour tous les projets de petites constructions ou extensions de moins de 40 m2, piscines,
                abris de jardin, clôtures, panneaux solaires, modifications de façades etc.</p>
                <p className="talent-list-text">Le dossier est sensiblement le même que pour un permis de construire mais il est délivré par la mairie
                dans un délai de 1 mois (hors zone protégée).</p>
                <p className="talent-list-text"> Une fois l’autorisation obtenue, un panneau de chantier doit être affiché sur le terrain pendant toute la
                durée des travaux et les travaux doivent commencer dans un délai de trois ans.</p>
                <p className="talent-list-text">
                  <a className="talent-link" href="https://www.service-public.fr/particuliers/vosdroits/R2028">
                    Déclaration préalable pour une maison individuelle et/ou ses annexes - DPMI (Formulaire 13703*10) | Service-public.fr
                  </a>
                </p>
              </div>
              </li>
            </ul>
            <img className="talent-img" 
            src={permisconstruire}
            srcSet={`${permisconstruire480} 480w`}
            sizes="(max-width: 480px)" 
            alt="permis-construire" 
            />
          </div>
      </div>
    </div>
  );
}

export default talent;
